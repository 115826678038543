<template>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert text dense color="#8061c2" border="left" class="pl-5">
                <h1 class="title mt-1">Historial de archivos multimedia</h1>
                <p class="desc mt-0 mb-1">
                    Aquí puedes ver el historial de los archivos multimedia que han sido subidos por los usuarios.
                </p>
            </v-alert>
        </v-col>

        <!-- Search Bar -->
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field v-model="search" color="#8061c2" label="Buscar " append-icon="mdi-magnify" single-line
                    hide-details outlined class="mb-2 mx-0 px-0" @input="fetchMedia"></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select v-model="filter" :items="filterOptions" label="Filtrar por" outlined hide-details></v-select>
            </v-col>

            <v-col cols="12" md="4" class="px-0 ">
                <v-select v-model="order" :items="orderOptions" label="Ordenar" outlined hide-details></v-select>
            </v-col>
        </v-row>


        <v-data-table :headers="headers" :items="media" :loading="loading" class="elevation-1 mx-3 px-2 py-4"
            :items-per-page="perPage" :server-items-length="totalMedia" :page.sync="page" :footer-props="{
                itemsPerPageOptions: [50, 100, 200, 300, -1],
                showFirstLastPage: true
            }" @update:page="fetchMedia" @update:items-per-page="changePerPage">
            <template v-slot:item.path="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div class="miniatura clickable-media" @click="showMedia(item.path, item.type)"
                            @mouseover="hoveredMedia = item.path" @mouseleave="hoveredMedia = null" v-bind="attrs"
                            v-on="on">
                            <template v-if="item.type === 'video'">
                                <video :src="item.path" :autoplay="true" :loop="true" muted :preload="'auto'"
                                    :playsinline="true" v-if="item.path"></video>
                            </template>
                            <template v-else>
                                <img :src="item.path" v-if="item.path" />
                            </template>
                        </div>
                    </template>
                    <span>Ver</span>
                </v-tooltip>
            </template>

            <template v-slot:item.status_media="{ item }">
                <v-chip :color="getStatusColor(item.status_media_id)" dark small>
                    {{ item.status_media }}
                </v-chip>
            </template>


            <template v-slot:item.apro="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="green" v-bind="attrs" v-on="on" @click="approveMedia(item.id)"
                            :loading="item.loading" :disabled="item.loading">
                            <v-icon>mdi-image-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprobar</span>
                </v-tooltip>


                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="red" v-bind="attrs" v-on="on" :loading="item.loading"
                            :disabled="item.loading" @click="rejectMedia(item.id)">
                            <v-icon>mdi-image-remove</v-icon>
                        </v-btn>
                    </template>
                    <span>Rechazar</span>
                </v-tooltip>
            </template>


            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmony(item.site_slug)">
                            <v-icon>mdi-web</v-icon>
                        </v-btn>
                    </template>
                    <span>Ir al Harmony</span>
                </v-tooltip>

                <!-- Botón para impersonar usuario con tooltip -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on"
                            @click="impersonateUser(item.user_id)">
                            <v-icon>mdi-account-switch</v-icon>
                        </v-btn>
                    </template>
                    <span>Impersonar Usuario</span>
                </v-tooltip>
            </template>
        </v-data-table>



        <!-- Modal para ampliar el logo -->
        <v-dialog v-model="dialog" max-width="95vh" width="unset" class="rounded-lg dialog-media">
            <div class="media-container">
                <video v-if="mediaType === 'video'" :src="selectedLogo" :autoplay="true" :loop="true" muted
                    :preload="'auto'" :playsinline="true" class="transition-ease cursor-pointer media"></video>
                <img v-else :src="selectedLogo" class="transition-ease cursor-pointer media" :alt="'Image'" />
            </div>
        </v-dialog>
    </v-col>
</template>

<script>
import axios from '@/axios.js';

export default {
    data() {
        return {
            roles: [],
            search: '',
            loading: true,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            media: [],
            totalMedia: 0,
            dialog: false,
            dialogDetails: false,
            mediaType: 'image',
            filter: 'created_at',
             order: 'asc',
            headers: [
                { text: 'Usuario', value: 'email' },
                { text: 'Harmony', value: 'site' },
                { text: 'Imagen/Video', value: 'path', align: 'start', sortable: false },
                { text: 'Estado', value: 'status_media' },
                { text: 'Fecha', value: 'created_at' },
                {text: 'Aprobar/Rechazar', value: 'apro',  align: 'center',  sortable: false},
                { text: 'Opciones', value: 'actions', sortable: false }
            ],
            filterOptions: [
                {
                    text: 'Fecha Creación',
                    value: 'created_at'
                },
            ],
            orderOptions: [
                {
                    text: 'Mayor',
                    value: 'desc'
                },
                {
                    text: 'Menor',
                    value: 'asc'
                }
            ],
        };
    },


    created() {
        this.fetchMedia();
    },

    methods: {
        showMedia(media, type) {
            this.mediaType = type;
            this.selectedLogo = media;
            this.dialog = true;
        },

        showDetails(item) {
            this.withdrawalMethod = item.payment_method;
            this.paymentDetails = item.payment_details || {};
            this.dialogDetails = true;
        },

        async fetchMedia(){
            this.loading = true;
            try {
                const response = await axios.get('/admin/get-media', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        list: 'pending_media',
                        order: this.order,
                        filter: this.filter
                    }
                });
                this.media = response.data.media.map(media => ({
                    ...media,
                    loading: false
                }));
                this.totalMedia = response.data.total;

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },


        approveMedia(id) {
            const index = this.media.findIndex(media => media.id === id);
            this.$swal({
                title: 'Aprobar archivo',
                text: '¿Estás seguro de que deseas aprobar este archivo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {

                    this.media[index].loading = true;
                    try {
                        const response = await axios.post('/admin/approve-media', {
                            id: id
                        });
                        this.fetchMedia();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: 'Archivo aprobado',
                                text: 'El archivo ha sido aprobado exitosamente',
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Ha ocurrido un error al aprobar el archivo');
                    } finally {
                        this.$root.$emit('updateMedia');
                        this.media[index].loading = false;
                    }
                }
            });
        },

        async rejectMedia(id) {
            const index = this.media.findIndex(media => media.id === id);
            this.$swal({
                title: 'Rechazar archivo',
                text: '¿Estás seguro de rechazar el archivo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#8e84c0',
                cancelButtonColor: '#d33',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.media[index].loading = true;
                    try {
                        const response = await axios.post('/admin/reject-media', {
                            id: id
                        });
                        this.fetchMedia();
                        if (response.data.success) {
                            this.$vs.notify({
                                title: 'Archivo rechazado',
                                text: 'El archivo ha sido rechazado exitosamente',
                                color: 'success',
                                icon: 'check_box',
                                position: 'bottom-center',
                                time: 5000,
                            });
                        }
                    } catch (error) {
                        console.error(error);
                        this.$toast.error('Ha ocurrido un error al rechazar el archivo');
                    } finally {
                        this.$root.$emit('updateMedia');
                        this.media[index].loading = false;
                    }
                }
            });
        },


        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        },


        async impersonateUser(user_id) {
            try {
                const response = await axios.post('/admin/impersonate-user', {
                    user_id: user_id
                });

                let admin = localStorage.getItem("user");
                localStorage.setItem("impersonate_admin", JSON.stringify(admin));

                // Guardar el nuevo token y datos de usuario en el local storage
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("token", response.data.token);

                this.$vs.notify({
                    title: 'Impersonación exitosa',
                    text: `Ahora estás logueado como ${response.data.user.first_name}`,
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                });

                // Redirigir al panel correspondiente basado en el rol del usuario
                if (response.data.user.role_id === 1) {
                    this.$router.push('/panel/admin');
                } else {
                    this.$router.push('/panel');
                }
            } catch (error) {
                this.$vs.notify({
                    title: 'Ha ocurrido un error',
                    text: 'No se pudo impersonar al usuario.',
                    color: 'danger',
                    icon: 'warning',
                    position: 'bottom-center',
                });
                console.error(error);
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reiniciar a la primera página al cambiar los elementos por página
            this.fetchMedia();
        },

        getStatusColor(statusName) {
            if (statusName === 1 ) {
                return '#2e8b57';
            } else if (statusName === 2) {
                return '#f29339';
            } else if (statusName === 3) {
                return '#f44336';
            }
        },
    },

    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchMedia();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchMedia();
            }
        }
    },
};
</script>

<style scoped>
.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

 .miniatura {
  width: 100px;
  height: 40px;
  overflow: hidden;
}

.clickable-media {
  cursor: pointer;
}

.miniatura img,
.miniatura video {
  width: 100%;
  height: 100%;
} 

.dialog-media {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-media .v-dialog {
    box-shadow: none !important;
}

.media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
}

.media {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.v-dialog__content--active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-dialog__content--active .v-dialog {
    width: auto !important;
    max-width: 95vh;
}
</style>
